<template>
    <div class="main detailBox" :style="{ backgroundImage: `url(${playerBag})` }">
        <div class="playerContent">
            <!-- 导航 -->
            <page-head class="nav_back" :src="require('@/assets/images/common/nav-back-black.svg')">
                <template #right>
                    <filter-date :date="date" :monthsLength="12" @change="handleChangeDate"></filter-date>
                </template>
            </page-head>
            <iframe width="100%" class="iframeDom" ref="iframeRefDom" id="iframeDom" :src="detailUrl"
                    frameborder="0"
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"></iframe>
        </div>
    </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, ref, watch} from 'vue';
import store from "../../store";
import {useStore} from "vuex";

export default defineComponent({
    setup() {
        const route = useRoute()
        const router = useRouter()
        const {proxy} = getCurrentInstance();
        const store = useStore()
        const {playerBag, color} = proxy.$Config.UI_CONFIG
        const {publicWedUrl, cmsUrl} = proxy.$Config

        const state = reactive({
            playerBag,
            iframeRefDom: '',
            detailUrl: publicWedUrl + '/elite-talent-pool',
            date: [
                proxy.$moment().subtract(12, 'months').format('YYYY/MM/DD'),
                proxy.$moment().format('YYYY/MM/DD')
            ]
        });

        state.detailUrl = state.detailUrl +
            `?id=${route.query.id}&xTenantId=${store.state.tenantData.id}&startDate=${state.date[0]}&endDate=${state.date[1]}&token=${store.state.token}&api=${cmsUrl}/teamup-elite&appType=uniapp`

        onMounted(() => {

        })

        // 时间筛选时
        const handleChangeDate = (date) => {
            state.date = date;
            state.iframeRefDom.contentWindow.postMessage({
                code:'changeDate',
                startDate: date[0],
                endDate: date[1]
            },publicWedUrl)
        };
        // postMessage 方法，传递的第二个参数都是 *，这里的含义是指任何域名都能接收消息。
        // 建议如果知道消息接收方的域名，第二个参数请传递消息接收方的域名。因为这里是会存在安全隐患的，
        // 任何站点都可以向你的站点发送消息，如果没有做相关安全处理，是很容易造成攻击的。

        return {
            ...toRefs(state),

            handleChangeDate
        }
    }
})
</script>

<style scoped lang="scss">

.detailBox {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    box-sizing: border-box;
    padding: 60px 40px;

    .playerContent {
        position: relative;
        border-radius: 20px;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: #f3f3f3;
        /*返回按钮*/
        .nav_back {
            width: calc(100% - 64px);
            position: absolute;
            top: 32px;
            left: 32px;
            z-index: 3;
            padding: 0;
        }

        .iframeDom {
            margin-top: 110px;
            height: calc(100% - 110px);
        }
    }
}

::v-deep.navigation-mian {
    justify-content: space-between;
}
</style>
